<template>
  <landing-central-card :show-maintenance="false">
    <v-card class="flexcard" :elevation="$vuetify.breakpoint.xsOnly?0:8" :min-height="600" style="border-radius: 15px;"
            :style="'padding-bottom:15px;'+($vuetify.breakpoint.xsOnly?'margin-top:90px;':'')">
      <v-card-text>
        <v-layout justify-center align-center>
          <v-flex sm6 md4 lg3>
            <v-img contain :src="inProgress || isScheduled ? require('@/assets/svg/maintenance.svg') : require('@/assets/svg/leaves.svg')"/>
          </v-flex>
        </v-layout>
      </v-card-text>
      <v-card-text :key="key">
        <v-layout justify-center align-start>
          <v-flex md10 v-if="inProgress">
            <h4 class="starling-h4 text-center">{{ $t('common.maintenance.in_progress.title') }}</h4>
            <p class="starling-body text-center" v-for="(message,index) in $t('common.maintenance.in_progress.message')" v-html="message" :key="index"></p>
            <p class="starling-body text-center text-italic" v-if="remaining">{{$t('common.maintenance.in_progress.estimation', {remaining}) }}</p>
            <p class="starling-body text-center text-italic" v-if="!remaining">{{$t('common.maintenance.in_progress.unknown_estimation', {remaining}) }}</p>
          </v-flex>
          <v-flex md10 v-else>
            <template v-if="isScheduled">
            <h4 class="starling-h4 text-center">{{ $t('common.maintenance.scheduled.title') }}</h4>
            <p class="starling-body text-center" v-for="(message,index) in $t('common.maintenance.scheduled.message')" v-html="message" :key="index"></p>
            <p class="starling-body text-center text-italic" v-if="remainingBeforeMaintenance">{{$t('common.maintenance.scheduled.estimation', {remainingBeforeMaintenance}) }}</p>
            <p class="text-center">
              <primary-button large class="mt-3" @click="goToApp()">{{ $t('common.actions.enter_starling') }}</primary-button>
            </p>
            </template>
            <template v-else>
            <h4 class="starling-h4 text-center">{{ $t('common.maintenance.done.title') }}</h4>
            <p class="starling-body text-center" v-for="(message,index) in $t('common.maintenance.done.message')" v-html="message" :key="index"></p>
            <p class="text-center">
              <primary-button large class="mt-3" @click="goToApp()">{{ $t('common.actions.enter_starling') }}</primary-button>
            </p>
            </template>
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-card>
  </landing-central-card>
</template>

<script>
import LandingCentralCard from '@/views/landing/landing-central-card';
import Vue from 'vue';
import store from '@/scripts/store';
import PrimaryButton from '@/views/components/button/primary-button';

export default {
  name: 'maintenance',
  components: {
    PrimaryButton,
    LandingCentralCard,
  },
  data() {
    return { now: this.$moment(), key: 0, interval: null, polling: null, pingOK: false };
  },
  beforeRouteEnter(to, from, next) {
    if (from) {
      store.commit('setAfterLoginRoute', from.fullPath);
      next();
    }
  },
  computed: {
    maintenance() {
      return this.$store.getters.maintenance;
    },
    inProgress() {
      if (!this.now) return true;
      if (!this.pingOK) return true;
      if (this.now && this.maintenance && this.now.isAfter(this.$moment(this.maintenance.start))) return true;
      return false;
    },
    isScheduled() {
      if (this.maintenance && this.now.isBefore(this.$moment(this.maintenance.start))) return true;
      return false;
    },
    remaining() {
      if (!this.maintenance) return null;
      const end = this.$moment(this.maintenance.start).add(this.maintenance.duration);
      if (end.isBefore(this.now)) {
        return this.$t('common.maintenance.in_progress.over_estimated');
      }
      return Vue.filter('durationFormat')(end.diff(this.now));
    },
    remainingBeforeMaintenance() {
      if (!this.maintenance) return null;
      const end = this.$moment(this.maintenance.start);
      return Vue.filter('durationFormat')(end.diff(this.now));
    },
  },
  created() {
    this.interval = setInterval(() => {
      this.now = this.$moment();
      this.$forceUpdate();
      this.key++;

      if (this.maintenance && this.now.isSame(this.maintenance, 'second')) {
        this.ping();
      }
    }, 1000);

    this.polling = setInterval(() => {
      this.ping();
    }, 60000);
    this.ping();
  },
  destroyed() {
    clearInterval(this.interval);
    clearInterval(this.polling);
  },
  methods: {
    goToApp() {
      this.$router.replace({ name: 'home' });
    },
    ping() {
      this.$store.dispatch('ping').then(res => {
        this.pingOK = res.status === 204;
      });
    },
  },
};
</script>
