var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "landing-central-card",
    { attrs: { "show-maintenance": false } },
    [
      _c(
        "v-card",
        {
          staticClass: "flexcard",
          staticStyle: { "border-radius": "15px" },
          style:
            "padding-bottom:15px;" +
            (_vm.$vuetify.breakpoint.xsOnly ? "margin-top:90px;" : ""),
          attrs: {
            elevation: _vm.$vuetify.breakpoint.xsOnly ? 0 : 8,
            "min-height": 600
          }
        },
        [
          _c(
            "v-card-text",
            [
              _c(
                "v-layout",
                { attrs: { "justify-center": "", "align-center": "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { sm6: "", md4: "", lg3: "" } },
                    [
                      _c("v-img", {
                        attrs: {
                          contain: "",
                          src:
                            _vm.inProgress || _vm.isScheduled
                              ? require("@/assets/svg/maintenance.svg")
                              : require("@/assets/svg/leaves.svg")
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-text",
            { key: _vm.key },
            [
              _c(
                "v-layout",
                { attrs: { "justify-center": "", "align-start": "" } },
                [
                  _vm.inProgress
                    ? _c(
                        "v-flex",
                        { attrs: { md10: "" } },
                        [
                          _c("h4", { staticClass: "starling-h4 text-center" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t("common.maintenance.in_progress.title")
                              )
                            )
                          ]),
                          _vm._l(
                            _vm.$t("common.maintenance.in_progress.message"),
                            function(message, index) {
                              return _c("p", {
                                key: index,
                                staticClass: "starling-body text-center",
                                domProps: { innerHTML: _vm._s(message) }
                              })
                            }
                          ),
                          _vm.remaining
                            ? _c(
                                "p",
                                {
                                  staticClass:
                                    "starling-body text-center text-italic"
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "common.maintenance.in_progress.estimation",
                                        { remaining: _vm.remaining }
                                      )
                                    )
                                  )
                                ]
                              )
                            : _vm._e(),
                          !_vm.remaining
                            ? _c(
                                "p",
                                {
                                  staticClass:
                                    "starling-body text-center text-italic"
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "common.maintenance.in_progress.unknown_estimation",
                                        { remaining: _vm.remaining }
                                      )
                                    )
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        2
                      )
                    : _c(
                        "v-flex",
                        { attrs: { md10: "" } },
                        [
                          _vm.isScheduled
                            ? [
                                _c(
                                  "h4",
                                  { staticClass: "starling-h4 text-center" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "common.maintenance.scheduled.title"
                                        )
                                      )
                                    )
                                  ]
                                ),
                                _vm._l(
                                  _vm.$t(
                                    "common.maintenance.scheduled.message"
                                  ),
                                  function(message, index) {
                                    return _c("p", {
                                      key: index,
                                      staticClass: "starling-body text-center",
                                      domProps: { innerHTML: _vm._s(message) }
                                    })
                                  }
                                ),
                                _vm.remainingBeforeMaintenance
                                  ? _c(
                                      "p",
                                      {
                                        staticClass:
                                          "starling-body text-center text-italic"
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "common.maintenance.scheduled.estimation",
                                              {
                                                remainingBeforeMaintenance:
                                                  _vm.remainingBeforeMaintenance
                                              }
                                            )
                                          )
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _c(
                                  "p",
                                  { staticClass: "text-center" },
                                  [
                                    _c(
                                      "primary-button",
                                      {
                                        staticClass: "mt-3",
                                        attrs: { large: "" },
                                        on: {
                                          click: function($event) {
                                            return _vm.goToApp()
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "common.actions.enter_starling"
                                            )
                                          )
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ]
                            : [
                                _c(
                                  "h4",
                                  { staticClass: "starling-h4 text-center" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("common.maintenance.done.title")
                                      )
                                    )
                                  ]
                                ),
                                _vm._l(
                                  _vm.$t("common.maintenance.done.message"),
                                  function(message, index) {
                                    return _c("p", {
                                      key: index,
                                      staticClass: "starling-body text-center",
                                      domProps: { innerHTML: _vm._s(message) }
                                    })
                                  }
                                ),
                                _c(
                                  "p",
                                  { staticClass: "text-center" },
                                  [
                                    _c(
                                      "primary-button",
                                      {
                                        staticClass: "mt-3",
                                        attrs: { large: "" },
                                        on: {
                                          click: function($event) {
                                            return _vm.goToApp()
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "common.actions.enter_starling"
                                            )
                                          )
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ]
                        ],
                        2
                      )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }